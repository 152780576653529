import React from 'react';

import NProgressBar from './components/NProgressBar/NProgressBar';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Doc from './pages/main/doc';
import Pdf from './pages/main/pdf';

function App() {
  return (
    <Router>
      <NProgressBar />
      <Switch>
        <Route path="/view_pdf_all_page" exact component={Pdf} />
        <Route path="/*" exact component={Doc} />
      </Switch>
    </Router>
  );
}

export default App;
