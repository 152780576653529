function page(list, breadcrumbs, item) {
  item.breadcrumbs = breadcrumbs + ' / ' + `<a href="#${item.id}">${item.title}</a>`;
  list.push(item);
}

function pages(list, breadcrumbs, data) {
  for (const item of data) {
    if (Array.isArray(item.children)) {
      const title = breadcrumbs ? breadcrumbs + ' / ' + `<a href="#${item.id}">${item.title}</a>` : `<a href="#${item.id}">${item.title}</a>`;
      item.navi = true;
      list.push(item);
      pages(list, title, item.children);
    } else {
      page(list, breadcrumbs, item);
    }
  }
}

function getSource() {
  if (window.location.host.indexOf('house') !== -1) {
    return 'intrahouse';
  }
  if (window.location.host.indexOf('opc') !== -1) {
    return 'intraopc';
  }
  return 'intrascada';
}

export function getPages(data) {
  const source = getSource();

  let list = [{ id: `first_sheet_${source}`, system: true }];

  pages(list, undefined, data);

  //list = list.slice(0, 10);
  list.push({ id: `last_sheet_${source}`, system: true });

  return list;
}

