import React, { Component } from 'react';
import { getPages } from './tools';
import { makeContent } from './maker';

import { Previewer, registerHandlers, Handler } from 'pagedjs';

import clsx from 'clsx';

import MarkdownElement from '../../../components/MarkDown/MarkdownElement';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  markdownElement: {
    marginBottom: theme.spacing(2),
    padding: '0px 8px',
    paddingRight: 16,
  },
  markdownElementBlog: {
    maxWidth: 700,
    margin: 'auto',
    padding: 0,
    fontSize: theme.typography.pxToRem(18),
    fontFamily: `Roboto Slab, ${theme.typography.fontFamily}`,
    fontWeight: 300,
    '& p, & ul, & ol': {
      lineHeight: 1.7,
    },
    '& strong': {
      fontWeight: 400,
      fontFamily: theme.typography.fontFamily,
    },
    '& img': {
      display: 'block',
      margin: 'auto',
    },
    '& .blog-description': {
      fontSize: theme.typography.pxToRem(14),
      textAlign: 'center',
    },
  },
});

function getSource() {
  if (window.location.host.indexOf('house') !== -1) {
    return 'intrahouse';
  }
  if (window.location.host.indexOf('opc') !== -1) {
    return 'intraopc';
  }
  return 'intrascada';
}

const source = getSource();

class Pdf extends Component {
  state = { data: '' }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get('lang') || 'ru';

    fetch(`restapi/tree?source=${source}&lang=${lang}&pdf=1`)
    .then(res => res.json())
    .then(json => this.parseTree(json.data));
  }

  parseTree = (data) => {
    const pages = getPages(data);

    Promise
      .all(pages.map(this.getPage))
      .then(list => {
        this.setState({ data: makeContent(data, list) }, this.afterRender);
      })
  }

  afterRender = () => {
    const _warn = window.console.warn;
    let err = 0;

    window.console.warn = function() {
      err = err + 1;
      _warn.apply(null, arguments);
    }

    const paged = new Previewer();
    const flow = paged.preview(document.getElementById('#md'), ['/static/css/pdf.css'], document.body).then((flow) => {
      const list = document.getElementsByClassName('pagedjs_pages');
      let prevBreadcrumbs;

      for (const item of list[0].childNodes) {
        if (item.tagName === 'DIV') {

          if (item.querySelector('.sclear')) {
            const node = item.querySelector('.pagedjs_margin-bottom');
            node.innerHTML = '';
            const node2 = item.querySelector('.pagedjs_margin-bottom-right-corner-holder');
            node2.innerHTML = '';
            const node3 = item.querySelector('.pagedjs_margin-bottom-left-corner-holder');
            node3.innerHTML = '';
          }

          const breadcrumbs = item.querySelector('.breadcrumbs');
     
          if (breadcrumbs) {
            prevBreadcrumbs = breadcrumbs.cloneNode(true);
            breadcrumbs.innerHTML = '';
          }

          if (item.querySelector('.sclear') === null && prevBreadcrumbs) {
            const target = item.querySelector('.pagedjs_margin-top');
            const node = prevBreadcrumbs.cloneNode(true)
            
            target.style.display = 'flex';
            target.style.alignItems = 'center';
            target.innerHTML = '';
            
            node.style.display = 'block';

            target.appendChild(node);
          }
        }
      }

      document.title = source;

      if (err === 0) {
        alert('Готово!')
      } else {
        alert('Найдено ошибок: ' + err)
      }
    })
  }

  getPage = (item) => {
    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get('lang') || 'ru';
    
    return new Promise((resolve, reject) => {
      if (item.navi) {
        item.data = '';
        resolve(item);
      } else {
        fetch(`/restapi/page?source=${source}&lang=${lang}&id=` + item.id)
        .then(res => res.json())
        .then(json => {
          item.data = json.data;
          resolve(item);
        })
        .catch(reject)
      }
    });
  }

  render({ classes } = this.props) {
    return (
      <>
        <div id="pdf"></div>
        <MarkdownElement
          id="md"
          text={this.state.data}
          className={clsx(classes.markdownElement, { [classes.markdownElementBlog]: false })}
        />
      </>
    )
  }
}

export default withStyles(styles)(Pdf);

