function header(item) {
  return `<div class="breadcrumbs" style="display:color:#1976d2;none;text-align:right;color:#000000de;width:100%;">${item.breadcrumbs}</div>\n\n`
}

function content(item) {
  let link = `<p id=${item.id} style="margin: 0;visibility: hidden;height: 0;"></p>\n\n`;

  if (item.alias) {
    link = link + `<p id=${item.alias} style="margin: 0;visibility: hidden;height: 0;"></p>\n\n`;
  }

  if (item.navi) {
    return link;
  }
  return link + item.data.replaceAll('](/ru/', '](#')
}

function pagebreake(item) {
  return '\n<div class="break-page"></div>\n';
}

function tableOfConent(list) {
  let content = '';
  let n = 0;

  function pages(data, level, test) {    
    let i = 0;

    for (const item of data) {      
      if (level === 0) {
        n = n + 1;
      }

      i = i + 1;

      if (Array.isArray(item.children)) {  
        if (level === 0) {
          const stub = '  '.repeat(level);
          const numbering = n + ' ';
          const text = 'Глава ' + numbering + item.title;
       
          content = content + stub + `<li><a class="abold" href="#${item.id}">${text}</a><span data-id="#${item.id}" class="tocpage"></span></li>\n`
        } else {
          const stub = '  '.repeat(level);
          const numbering = test + i.toString() + '. ';
          const text = numbering + item.title;

          content = content + stub + `<li><a href="#${item.id}">${text}</a><span data-id="#${item.id}" class="tocpage"></span></li>\n`
        }

        content = content + '  '.repeat(level) + `<li class="nested"><ul class="inner">\n`
        
        pages(item.children, level + 1, level === 0 ? n + '.' : test + i.toString() + '.')
        
        content = content + '  '.repeat(level) + `</ul></li>\n`
      } else {
        const stub = '  '.repeat(level);
        const numbering = (test || '') + i.toString() + '. ';
        const text = numbering + item.title;

        content = content + stub + `<li><a href="#${item.id}">${text}</a><span data-id="#${item.id}" class="tocpage"></span></li>\n`
      }
    }
  }

  pages(list, 0);
 
  return `<div class="tableOfConent"><ul class="outer">\n${content}</ul></div>`;
}

function getSource() {
  if (window.location.host.indexOf('house') !== -1) {
    return 'intrahouse';
  }
  if (window.location.host.indexOf('opc') !== -1) {
    return 'intraopc';
  }
  return 'intrascada';
}


export function makeContent(tree, pages) {
  const titles = {
    intrascada: 'IntraSCADA',
    intrahouse: 'IntraHouse',
    intraopc: 'IntraOPC',
  };
  const source = getSource();

  let data = pages;
  let md = `<div class="stest"><p class="lfooter">${(new Date()).toLocaleDateString()}</p><p class="pfooter">${titles[source]}</p>`;
  let first = false;
  let end = false;

  if (data[0] &&  data[0].system && data[0].data) {
   first = true;
   data = data.slice(1);
  }

  if (data[data.length - 1] && data[data.length - 1].system && data[data.length - 1].data) {
    end = true;
    data = data.slice(0, -1);
  }

  if (first) {
    md = md + '<div class="sclear"></div>' + pages[0].data;
  }

  md = md + tableOfConent(tree) + pagebreake();; 

  for (const item of data) {
    if (item.navi) {
      md = md + content(item);
    } else {
      md = md + header(item); 
      md = md + content(item);
      md = md + pagebreake();
    }
  }

  if (end) {
    md = md + '<div class="sclear"></div>' + pages[pages.length - 1].data;
  }

  return md;
}